import logo from './logo.svg';
import './App.css';
import React, { useEffect, useState, Component } from 'react';
import { withAuthenticator, AmplifySignOut } from '@aws-amplify/ui-react'
import Amplify, { Auth, Storage } from 'aws-amplify';

function uuidv4() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    var r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
}

async function upload(file, extension, filetype, app) {
	const email = app.state.email;
	const uuid = uuidv4();
	const body = {
		'email': app.state.email,
		'uuid': uuid
	};
	fetch('https://radcadapi.thetatech.ai/pre-upload', {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json'
		},
		body: JSON.stringify(body)
	})
	.then(response => {
		if (response.ok) {
			app.setState({ uploadStatus: "Upload initialized..." });
			Storage.put(uuid + extension,
				    file,
				    {
				      contentType: filetype
				    }
			)
			.then(result => {
				app.upload = null;
				app.setState({ uploadStatus: "File uploaded! It may take a few minutes until that file is processed." });
			})
			.catch(err => {
				app.setState({ uploadStatus: `Cannot upload file: ${err}` });
			});
		}
		else {
			app.setState({ uploadStatus: 'Access Denied. Have you registered your email address at https://ccipd.thetatech.ai?' });
		}
	})
	.catch(error => {
		console.error('Error:', error);
		app.setState({ uploadStatus: error.message});
	});	
}

class App extends Component {
  state = {
    email: "loading...",
    zipName: "",
    zipFile: "",
    uploadStatus: "Please select a zip file."
  };

  async componentDidMount() {
    let user = await Auth.currentAuthenticatedUser();
    const { attributes } = user;
    //console.log('Attributes = ')
    //console.log(attributes)
    const email = attributes['email'];
    this.setState({ email });
  }
  
  uploadZip = () => {
    this.setState({uploadStatus:'Uploading...'});
    const file = this.upload.files[0];
    if (!file) {
      this.setState({uploadStatus:'Error: Unable to read file.'});
    }
    else {
      const filetype = file.type;
      if (filetype != 'application/zip') {
        this.setState({uploadStatus:'Error: Please select a .zip file.'});
      }
      else {
	const extension = '.zip';
	upload(file, extension, filetype, this);
      }
    }
  };

  render() {
    
    return (
          
      <div className="App">
        <header className="App-header">
	<p/>
        <a href='https://ccipd.case.edu/' target='_blank'>
          <img src={logo} className="App-logo" alt="logo" />
        </a>
        <h1>CCIPD Secure DICOM Uploader</h1>
        <a href='https://ccipd.case.edu/' target='_blank'>https://ccipd.case.edu</a>
	<p/>
        </header>
        
        <h2>Instructions: Upload a zip file containing anonymized DICOM files.</h2>
        <p className='warning'><b>Warning:</b> Please anonymize your data first.</p>
	<p className='warning'>Do not submit data with <a href='https://www.hhs.gov/hipaa/for-professionals/privacy/laws-regulations/index.html' target='_blank'>individually identifiable health information.</a></p>
        
        <input
          type="file"
          accept=".zip"
          style={{ display: "none" }}
          ref={ref => (this.upload = ref)}
          onChange={e =>
            this.setState({
              zipFile: this.upload.files[0],
              zipName: this.upload.files[0].name
            })
          }
        />
        
        <input value={this.state.zipName} placeholder="Select file" />
        
        <button
          onClick={e => {
            this.upload.value = null;
            this.upload.click();
          }}
          loading={this.state.uploading}
        >
          Browse
        </button>

        <button onClick={this.uploadZip}> Upload File </button>

        <p class='info'><b>Account: </b>{this.state.email}</p>
        <p className='info'><b>Upload Status:</b> {this.state.uploadStatus}</p>
	<p className='info'><b>Next Steps:</b> After uploading, wait a few minutes and click here to load the viewer: <a href='https://ohif.thetatech.ai' target='_blank'>ohif.thetatech.ai</a></p>
	<p/>
     
        <AmplifySignOut />
        
      </div>
    );
  }
}

export default withAuthenticator(App);
