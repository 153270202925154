/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_cognito_identity_pool_id": "us-east-2:ace48855-d42c-4eac-82f7-06becd8d6815",
    "aws_cognito_region": "us-east-2",
    "aws_user_pools_id": "us-east-2_5CoKM12Lh",
    "aws_user_pools_web_client_id": "6hckkh0kcm3tbfind3a69scdf5",
    "oauth": {
        "domain": "9lzk67mfdpyt-staging.auth.us-east-2.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "https://upload.ohif.thetatech.ai/,http://localhost:3000/",
        "redirectSignOut": "https://upload.ohif.thetatech.ai/,http://localhost:3000/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_login_mechanisms": [
        "EMAIL"
    ],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_NUMBERS",
            "REQUIRES_SYMBOLS",
            "REQUIRES_UPPERCASE"
        ]
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "ccipd-uploads05747-staging",
    "aws_user_files_s3_bucket_region": "us-east-2"
};


export default awsmobile;
